<template>
  <div class="flex w-fit">
    <div v-for="(button, idx) in data" :key="idx">
      <Button
        :label="button.title"
        :to="
          button.pageLink !== null
            ? (localePathByType(
                button.pageLink?.__typename as string,
                button.pageLink?.slug as string
              ) as string)
            : button.externalLink
        "
        :target="button.externalLink ? '_blank' : undefined"
        :variant="button.primary ? 'primary' : 'secondary'"
        :class="{ 'ml-4': idx === 1 }"
        :icon="{ name: 'arrow-right', size: 'lg', color: button.primary ? 'white' : 'black' }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CtaItem } from '~~/types/button'

const { localePathByType } = useLocalePathByType()

interface Props {
  data: CtaItem[]
}

defineProps<Props>()
</script>
